<template>
	<v-container
			fluid
	>
		<v-dialog v-model="contacts" max-width="500">
			<v-card>
				<v-card-title>Contacts</v-card-title>
				<v-card-text>
					<v-list v-for="(item,i) in contactsItems" :key="i">
						<v-list-item>
							<div>
								<div v-if="item.name">
									<span class="font-weight-bold">{{ item.name }}</span><span v-if="item.position"> ({{ item.position }})</span>
								</div>
								<div v-if="item.department">{{ item.department }}</div>
								<div>{{ item.email }}</div>
							</div>
						</v-list-item>
					</v-list>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-card-title>
			<v-spacer />
			<v-menu offset-y v-if="$authorized([`manager`])">
				<template v-slot:activator="{ on }">
					<Button v-on="on">
						<v-icon>mdi-download</v-icon>
					</Button>
				</template>
				<v-list>
					<v-list-item @click="downloadContacts">
						<v-list-item-title>Contacts</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>

		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								:options.sync="options"
								item-key="id"

								class="row-pointer"
								:footer-props="{'items-per-page-options':[50,100]}"
						>
							<template v-slot:[`body.prepend`]>
								<tr>
									<td>
										<v-text-field v-model="name" type="text" label="Name"></v-text-field>
									</td>
									<td></td>
									<td>
										<v-text-field v-model="country" type="text" label="Country"></v-text-field>
									</td>
									<td>
										<v-text-field v-model="initiatives" type="text" label="Initiatives"></v-text-field>
									</td>
									<td></td>
									<td></td>
								</tr>
							</template>
							<template v-slot:[`item.initiatives`]="{ item }">
								<v-chip x-small class="ma-1" v-for="(initiative, i) in item.initiatives" :key="i">{{ initiative.name }}</v-chip>
							</template>
							<template v-slot:[`item.info`]="{ item }">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on" small>
											mdi-information-outline
										</v-icon>
									</template>
									<div class="text-no-wrap">
										Profile Created: <span clsas="font-weight-bold">{{ item.created_at }}</span>
									</div>
									<div class="text-no-wrap">
										UNFCCC Synced: <span clsas="font-weight-bold">{{ item.profile_scanned }}</span>
									</div>
								</v-tooltip>
							</template>
							<template v-slot:[`item.contacts`]="{ item }">
								<v-btn
									v-if="item.contacts > 0"
									x-small
									@click="showContacts(item)"
								>
									{{ item.contacts }}
								</v-btn>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App'

	export default {
		computed: {
			headers() {
				return [
					{
						text: `Name`,
						align: `left`,
						value: `name`,
						filter: f => (f + '').toLowerCase().includes(this[`name`].toLowerCase())
					},
					{
						text: `domain`,
						value: `domain`,
						sortable: false,
					},
					{
						text: `Country`,
						value: `country`,
						sortable: false,
						filter: f => (f + '').toLowerCase().includes(this[`country`].toLowerCase())
					},
					{
						text: `Initiatives`,
						value: `initiatives`,
						sortable: false,
						filter: f => !this[`initiatives`] || f && f.some(o => o.name.toLowerCase().includes(this[`initiatives`].toLowerCase()))
					},
					{
						text: `Contacts`,
						value: `contacts`,
						sortable: false,
					},
					{
						text: ``,
						value: `info`,
					},
				];
			}
		},
		data: () => ({
			loading: false,
			contacts: false,
			contactsItems: [],
			name: ``,
			country: ``,
			initiatives: ``,
			items: [],
			options: {},
			totalItems: 0,
		}),
		methods: {
			downloadContacts() {
				this.$httpInt.get(`profiles-of-interest/contacts`)
					.then(res => {
						this.items = res.data.result
						if (res.data.result.length !== 0) {
							let csv = 'Name,E-mail,Department,Position,Company Name,Company Country, Company Domain\n'
							res.data.result.forEach(function(row) {
								csv += Object.values(row).join(',')
								csv += "\n";
							})
							const link = document.createElement("a")
							link.setAttribute("href", 'data:text/csv;charset=utf-8,' + encodeURI(csv))
							link.setAttribute("download", "contacts.csv")
							link.click()
						}
					})
			},
			refreshContent() {
				this.loading = true

				const additional = {
					name: this.name,
					country: this.country,
					initiatives: this.initiatives,
				}

				this.$httpInt.get(`/v2/profile`, { params: this.$paginationParams(this, additional) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
			showContacts(profile) {
				this.contactsItems = []
				this.contacts = true
				this.$httpInt.get(`profiles-of-interest/${profile.id}/contacts`)
					.then(res => this.contactsItems = res.data.result)
			},
		},
		mixins: [globalMixIn],
		name: `ProfilesOfInterest`,
		mounted() {
			this.refreshContent()
		},
		watch: {
			name: {
				handler () {
					this.$debounce(() => this.refreshContent(), 300)
				},
				deep: true,
			},
			country: {
				handler () {
					this.$debounce(() => this.refreshContent(), 300)
				},
				deep: true,
			},
			initiatives: {
				handler () {
					this.$debounce(() => this.refreshContent(), 300)
				},
				deep: true,
			},
			options: {
				handler () {
					this.refreshContent()
				},
				deep: true,
			},
		},
	}
</script>
