var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.contacts),callback:function ($$v) {_vm.contacts=$$v},expression:"contacts"}},[_c('v-card',[_c('v-card-title',[_vm._v("Contacts")]),_c('v-card-text',_vm._l((_vm.contactsItems),function(item,i){return _c('v-list',{key:i},[_c('v-list-item',[_c('div',[(item.name)?_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))]),(item.position)?_c('span',[_vm._v(" ("+_vm._s(item.position)+")")]):_vm._e()]):_vm._e(),(item.department)?_c('div',[_vm._v(_vm._s(item.department))]):_vm._e(),_c('div',[_vm._v(_vm._s(item.email))])])])],1)}),1)],1)],1),_c('v-card-title',[_c('v-spacer'),(_vm.$authorized(["manager"]))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('Button',_vm._g({},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,false,3371643499)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.downloadContacts}},[_c('v-list-item-title',[_vm._v("Contacts")])],1)],1)],1):_vm._e()],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.options,"item-key":"id","footer-props":{'items-per-page-options':[50,100]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('td'),_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"Country"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"Initiatives"},model:{value:(_vm.initiatives),callback:function ($$v) {_vm.initiatives=$$v},expression:"initiatives"}})],1),_c('td'),_c('td')])]},proxy:true},{key:"item.initiatives",fn:function(ref){
var item = ref.item;
return _vm._l((item.initiatives),function(initiative,i){return _c('v-chip',{key:i,staticClass:"ma-1",attrs:{"x-small":""}},[_vm._v(_vm._s(initiative.name))])})}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('div',{staticClass:"text-no-wrap"},[_vm._v(" Profile Created: "),_c('span',{attrs:{"clsas":"font-weight-bold"}},[_vm._v(_vm._s(item.created_at))])]),_c('div',{staticClass:"text-no-wrap"},[_vm._v(" UNFCCC Synced: "),_c('span',{attrs:{"clsas":"font-weight-bold"}},[_vm._v(_vm._s(item.profile_scanned))])])])]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [(item.contacts > 0)?_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.showContacts(item)}}},[_vm._v(" "+_vm._s(item.contacts)+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }